import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import ReactPDF from "@react-pdf/renderer";
import loadingAnimation from "../../assets/loading.json";
import theme from "../../theme";
import diagnoseData from "../results";

import MyDocument from "./TestPrint";
import PDFDownloadLink = ReactPDF.PDFDownloadLink;

const CompletePage = (props: any) => {
  const { result, completePageDate } = props;
  const { t } = useTranslation();
  const [showAnimation, setShowAnimation] = useState(true);
  console.log("Re", result);
  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setShowAnimation(false);
    }, 2000);

    return () => clearTimeout(animationTimeout);
  }, []);

  const resultsIds = completePageDate
    .map((item: { id: any }) => item.id)
    .join(", ");

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      padding={3}
    >
      {showAnimation && (
        <Player
          autoplay
          loop
          src={loadingAnimation}
          style={{ height: "300px", width: "300px" }}
        />
      )}

      {!showAnimation && (
        <>
          <Box width="100%" marginBottom={2}>
            <Typography typography={"h2"} fontSize={22} align="center">
              {t("result.title")}
            </Typography>
            <Typography typography={"h3"} fontSize={22} align="center">
              Folgende Insulin-Pumpen haben die meisten Übereinstimmungen
            </Typography>
          </Box>
          {completePageDate && completePageDate.length > 0 ? (
            completePageDate
              .slice(0, 2) // Limit to 2 items
              .map((item: any, index: any) => (
                <Box key={index} sx={{
                  width: "100%",
                  marginBottom: 2,
                  [theme.breakpoints.up("md")]: {
                    width: "65%"
                  },
                  [theme.breakpoints.up("sm")]: {
                    width: "100%"
                  }
                }}
                     marginBottom={2}>
                  <Typography p={2} typography={"h1"} fontSize={22} align="center">
                    {index + 1}
                  </Typography>
                  <Typography p={1} typography={"h2"} fontSize={22} align="center">
                    {item.name}
                  </Typography>{item.video &&
                  < div className="iframe-container">
                    <iframe width="560" height="315" src={item.video}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                  </div>}
                  <Typography paddingY={2} fontSize={17}>
                    {item.description_de}
                  </Typography>
                  {/*
                <Button href={item.link} variant={"contained"}>More</Button>
*/}
                </Box>
              ))
          ) : (
            <Box width="100%" marginBottom={2}>
              <Typography variant="h6" align="center">
                {diagnoseData.default.description_de}
              </Typography>
            </Box>
          )}
          <Box>
            <PDFDownloadLink
              document={
                <MyDocument
                  result={result}
                  completePageDate={completePageDate}
                />
              }
              fileName="pump-o-maot-Auswertung.pdf"
            >
              {({ loading }) => (loading ? "Lädt ..." : "Download PDF")}
            </PDFDownloadLink>
          </Box>
          <Box height={20} />
          <Button
            component={RouterLink}
            size={"large"}
            to={`/newsletter/${resultsIds}`} // Include the userId in the URL path
            variant="contained"
          >
            {t("result.expertVideos")}
          </Button>

          <Box paddingY={4} />

          <Box width="100%" marginBottom={2}>
            <Typography typography={"h4"} fontSize={16} align="center">
              {t("result.allQuestions")}
            </Typography>
          </Box>

          {result &&
            result.map((item: any, index: number) => (
              <Box
                borderRadius={3}
                bgcolor={"white"}
                p={2}
                key={index}
                width={"90%"}
                marginBottom={2}
                sx={{
                  boxShadow: "4px 9px 8px 0px rgba(0,0,0,0.10)"
                }}
              >
                <Typography
                  sx={{ color: theme.palette.secondary.main }}
                  fontSize={12}
                >
                  {item && item.title}
                </Typography>
                <hr />
                <Typography fontWeight={"bold"} fontSize={16}>
                  {item && item.displayValue}
                </Typography>
                {index > 1 &&

                  <>
                    <br/>
                    <Typography fontSize={12}>
                    Medtronic780:
                    {item && item.data && item.data[0].scoremedtronic780 > 0 ? "√" : "X"}
                  </Typography><Typography fontSize={12}>
                    Dana:
                    {item && item.data && item.data[0].scoredana > 0 ? "√" : "X"}
                  </Typography><Typography fontSize={12}>
                    Omnipod:
                    {item && item.data && item.data[0].scoreomnipod5 > 0 ? "√" : "X"}
                  </Typography><Typography fontSize={12}>
                    tSlim x2:
                    {item && item.data && item.data[0].scoretslimx2 > 0 ? "√" : "X"}
                  </Typography><Typography fontSize={12}>
                    Ypsopump:
                    {item && item.data && item.data[0].scoreypsopump > 0 ? "√" : "X"}
                  </Typography></>
                }
              </Box>
            ))}
        </>
      )}
    </Box>
  );
};

export default CompletePage;
