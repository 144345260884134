import React, { useCallback, useState } from "react";
import "./App.css";
import "survey-core/survey.i18n";
import { Survey } from "survey-react-ui";

import * as SurveyCore from "survey-core";
import { Model, Serializer, StylesManager } from "survey-core";
// @ts-ignore
import { SurveyModel } from "survey";

// @ts-ignore
import { nouislider } from "surveyjs-widgets";
import ImportantNote from "./Pages/ImportantNote";

import "survey-core/defaultV2.min.css";
import "nouislider/dist/nouislider.min.css";

import surveyJson from "./survey/survey";

import CompletePage from "./survey/components/CompletePage";
import alertResults from "./utils/alertResults";
import { useTranslation } from "react-i18next";
import pumpData from "./survey/results";
import { test } from "./test";


nouislider(SurveyCore);
StylesManager.applyTheme("defaultV2");
// Add a custom `score` property to choice options
Serializer.addProperty("itemvalue", {
  name: "scoretslimx2:number"
});
Serializer.addProperty("itemvalue", {
  name: "scoremedtronic780:number"
});

Serializer.addProperty("itemvalue", {
  name: "scoreypsopump:number"
});
Serializer.addProperty("itemvalue", {
  name: "scoredana:number"
});
Serializer.addProperty("itemvalue", {
  name: "scoreomnipod5:number"
});

function App() {
  Serializer.addProperty("question", { name: "category:string" });
  const { t, i18n } = useTranslation();

  const survey = new Model(surveyJson);
  const [counter, setCounter] = React.useState(0);
  const [result, setResult] = useState<any>(null);
  const [completePageDate, setCompletePageData] = useState<any>(null);
  // const memoizedAlertResults = useCallback(alertResults, []);
  const pumpArray: { name: string; description: string; description_de: string; link: string; }[] = [];

   //survey.data = test;

  function calculateTotalScore(data: any) {
    let totalScoreTslimX2 = 0;
    let totalScoreMedtronic780 = 0;
    let totalScoreYpsopump = 0;
    let totalScoreDana = 0;
    let totalScoreOmnipod5 = 0;

    data.forEach((item: any) => {
      const question = survey.getQuestionByValueName(item.name);
      const qValue = item.value;

      // @ts-ignore
      if (question.choices) {
        // @ts-ignore
        const selectedChoice = question.choices.find(
          (choice: { value: any }) => choice.value === qValue
        );
        if (selectedChoice) {
          totalScoreTslimX2 += Number(selectedChoice.scoretslimx2) || 0;
          totalScoreMedtronic780 +=
            Number(selectedChoice.scoremedtronic780) || 0;
          totalScoreYpsopump += Number(selectedChoice.scoreypsopump) || 0;
          totalScoreDana += Number(selectedChoice.scoredana) || 0;
          totalScoreOmnipod5 += Number(selectedChoice.scoreomnipod5) || 0;
        }
      }

      // @ts-ignore
      if (question.rateValues) {
        // @ts-ignore
        const selectedRate = question.rateValues.find(
          (rate: { value: any }) => rate.value === qValue
        );
        if (selectedRate) {
          totalScoreTslimX2 += Number(selectedRate.scoretslimx2) || 0;
          totalScoreMedtronic780 +=
            Number(selectedRate.scoremedtronic780) || 0;
          totalScoreYpsopump += Number(selectedRate.scoreypsopump) || 0;
          totalScoreDana += Number(selectedRate.scoredana) || 0;
          totalScoreOmnipod5 += Number(selectedRate.scoreomnipod5) || 0;
        }
      }

      if (question.getType() === "matrix") {
        item.data.forEach((dataItem: any) => {
          if (!!dataItem.value) {
            totalScoreTslimX2 += Number(dataItem.scoretslimx2) || 0;
            totalScoreMedtronic780 +=
              Number(dataItem.scoremedtronic780) || 0;
            totalScoreYpsopump += Number(dataItem.scoreypsopump) || 0;
            totalScoreDana += Number(dataItem.scoredana) || 0;
            totalScoreOmnipod5 += Number(dataItem.scoreomnipod5) || 0;
          }
        });
      }
    });

    const scores = {
      totalScoreTslimX2,
      totalScoreMedtronic780,
      totalScoreYpsopump,
      totalScoreDana,
      totalScoreOmnipod5
    };

    return Object.entries(scores)
      .sort(([, a], [, b]) => b - a)
      .map(([key, value]) => ({ key, value }));
  }

  survey.onComplete.add((sender) => {
    // const maxScore = calculateMaxScore(sender.getAllQuestions());
    // Get survey results as a flat data array
    const plainData = sender.getPlainData({
      // Include `score` values into the data array
      calculations: [
        { propertyName: "scoretslimx2" },
        { propertyName: "scoremedtronic780" },
        { propertyName: "scoreypsopump" },
        { propertyName: "scoredana" },
        { propertyName: "scoreomnipod5" }
      ]
    });

    const surveryansers = sender.getAllValues();
    console.log("plainData", surveryansers);
    setResult(plainData);
    const totalScore = calculateTotalScore(plainData);
    console.log("sorted", totalScore);
    // console.log("first", totalScore[Object.keys(totalScore)[0]]); // Output: 3

    for (let i = 0; i < totalScore.length; i++) {
      const { key } = totalScore[i];
      let name, description, description_de, link;

      switch (key) {
        case "totalScoreTslimX2":
          pumpArray.push(pumpData.totalScoreTslimX2);
          name = "Tandem - t:slim X2";
          description =
            "Tandem t:slim X2 with intelligent algorithm and slim design. Flexible basal rates, delayed insulin delivery and automatic mode “Control IQ” in combination with Dexcom G6 sensor provide individual support for people with diabetes.";
          description_de =
            "Tandem t:slim X2 mit intelligentem Algorithmus und schlankem Design. Flexibel einstellbare Basalraten, verzögerte Insulinabgabe und automatischer Modus “Control IQ” in Verbindung mit Dexcom G6-Sensor bieten eine individuelle Unterstützung für Menschen mit Diabetes.";
          link = "https://www.heymeala.com/tandem-tslim-x2/";
          break;
        case "totalScoreMedtronic780":
          pumpArray.push(pumpData.totalScoreMedtronic780);

          name = "Medtronic 780G";
          description =
            "The Medtronic 780G is an excellent choice for people with diabetes who are looking for a system that allows for largely automated therapy and requires only minimal manual intervention.";
          description_de =
            "Die Medtronic 780G ist eine hervorragende Wahl für Menschen mit Diabetes, die ein System suchen, das eine weitgehend automatisierte Therapie ermöglicht und nur wenig manuelle Eingriffe erfordert.";
          link = "https://www.heymeala.com/medtronic-minimed-780/";
          break;
        case "totalScoreYpsopump":
          pumpArray.push(pumpData.totalScoreYpsopump);

          name = "Ypsomed";
          description =
            "The mylife YpsoPump - a compact and easy-to-use insulin pump with the innovative CamAPS FX system compatible. With the clear CamAPS FX app on your own smartphone, the system is easy to use and automatically determines the amount of insulin needed.";
          description_de =
            "Die mylife YpsoPump - eine kompakte und einfach zu bedienende Insulinpumpe mit dem innovativen CamAPS FX System kompatibel. Mit der übersichtlichen CamAPS FX-App auf dem eigenen Handy ist das System einfach zu nutzen und ermittelt automatisch die benötigte Insulinmenge.";
          link = "https://www.heymeala.com/mylife-ypsopump/";
          break;
        case "totalScoreDana":
          pumpArray.push(pumpData.totalScoreDana);
          name = "Dana RS bzw. Dana I";
          description =
            "Simple to use insulin pump system with mobile phone control";
          description_de =
            "Einfach zu bedienendes Insulinpumpensystem mit Handy-Steuerung";
          link = "https://www.heymeala.com/dana-rs-bzw-dana-i/";
          break;
        case "totalScoreOmnipod5":
          pumpArray.push(pumpData.totalScoreOmnipod5);

          name = "Insulet - Omnipod 5";
          description =
            "An innovative AID system without infusion set and tube, which has already achieved great success in the USA. The bolus calculator takes into account the CGM trend and the system is easy to use, but note that it needs a meal bolus for coverage and people who rarely give bolus may have less success.";
          description_de =
            "Ein innovatives AID-System ohne Infusionsset und Schlauch, das in den USA bereits große Erfolge verzeichnet. Der Bolusrechner berücksichtigt den CGM-Trend und das System ist einfach zu bedienen, aber beachten Sie, dass es für die Abdeckung von Mahlzeiten Bolus benötigt und Menschen, die selten Bolus abgeben, möglicherweise weniger Erfolg haben.";
          link = "https://www.heymeala.com/omnipod-5";
          break;
        default:
          pumpArray.push(pumpData.default);

          name = "-";
          description = "No description available.";
          description_de = "Keine Beschreibung verfügbar.";
          link = "";
      }
      let order = "";

      setCompletePageData(pumpArray);

      if (i === 0) {
        order = "first";
        if (i18n.language === "de") {
          sender.setValue(
            "first",
            `${name}:<br/> ${description_de}<br/> <a href="${link}" target="_blank">Mehr Informationen</a><br/>`
          );
        } else {
          sender.setValue(
            "first",
            `${name}:<br/> ${description}<br/> <a href="${link}" target="_blank">read more</a><br/>`
          );
        }
      } else if (i === 1) {
        order = "second";
        if (i18n.language === "de") {
          sender.setValue(
            "second",
            `${name}:<br/> ${description_de}<br/> <a href="${link}" target="_blank">Mehr Informationen</a><br/>`
          );
        } else {
          sender.setValue(
            "second",
            `${name}:<br/> ${description}<br/> <a href="${link}" target="_blank">read more</a><br/>`
          );
        }
      } else if (i === 2) {
        order = "third";
        if (i18n.language === "de") {
          sender.setValue(
            "third",
            `${name}:<br/> ${description_de}<br/> <a href="${link}" target="_blank">Mehr Informationen</a><br/>`
          );
        } else {
          sender.setValue(
            "third",
            `${name}:<br/> ${description}<br/> <a href="${link}" target="_blank">read more</a><br/>`
          );
        }
      }
      //  console.log(`${order} ${key}: ${value}`);
    }
    setCounter(2);
  });

  if (counter === 0) {
    // note before survey starts
    return <ImportantNote counter={counter} setCounter={setCounter} />;
  }
  if (counter === 1) {
    return (
      <div>
        <Survey model={survey} />
      </div>
    );
  }
  if (counter === 2) {
    // survey complete page
    return <CompletePage completePageDate={completePageDate} result={result} />;
  }
  return <div />;
}

export default App;
