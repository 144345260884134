import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontSize: 15,
    alignContent: "center",
    backgroundColor: "#E4E4E4"
  },
  section: {
    margin: 10,
    padding: 10,
    width: "80%"
  },
  question: {
    fontSize: 11,
    color: "#0c4843",
    padding: 2
  },
  answer: {
    fontSize: 11,
    color: "#000",
    padding: 2
  }
});

// Create Document Component
const MyDocument = (props: any) => {
  const { result, completePageDate } = props;
  const date = new Date().toDateString();
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            ...styles.section,
            backgroundColor: "#98d3d0",
            borderRadius: 10,
            padding: 16
          }}
        >
          <Text style={{ fontSize: 10, paddingBottom: 6 }}>
            Auswertung || {date}
          </Text>
          {
            completePageDate && completePageDate.length > 0
              ? completePageDate.map((item: any, index: any) => (
                <View key={index}>

                  <Text style={{ fontWeight: "bold" , paddingBottom:4}}>
                    {index + 1}. – {item?.name}
                  </Text>
                </View>
              ))
              : null // This will render nothing if the condition is not met
          }
        </View>
        <View style={styles.section}>
          <Text style={{ paddingBottom: 12 }}>Fragen und Antworten</Text>
          {result &&
            result.map((item: any, index: number) => (
              <View key={index} style={{ paddingBottom: 4 }}>
                <Text style={styles.answer}>{item && item.title}</Text>
                <Text style={styles.question}>{item && item.displayValue}</Text>
              </View>
            ))}
        </View>
        <View style={styles.section}>
          <Link src={"#"}>pump-o-mat</Link>
        </View>
      </Page>
    </Document>
  );
};
export default MyDocument;
