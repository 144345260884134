import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
//  import QuestionIcon from "../assets/Element 43.svg";

const ImportantNote = (props: {
  counter: number;
  setCounter: (counter: number) => void;
}) => {
  const { counter, setCounter } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box
        px={3}
        paddingTop={3}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {/*
        <img src={QuestionIcon} style={{ width: 170 }} alt="website logo" />
*/}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 2,
            maxWidth: 600,
          }}
        >
          <Typography
            typography={"h1"}
            typeof={"h1"}
            fontSize={22}
            p={1}
            fontWeight={"bold"}
          >
            {t("ImportantNote.title")}
          </Typography>
          <Typography fontSize={22} paddingTop={1} px={1}>
            {t("ImportantNote.text")}
          </Typography>
          <Typography p={1} fontSize={11}>
            {t("Disclaimer.text")}
          </Typography>

          <Box
            sx={{ justifyContent: "space-around", display: "flex", margin: 2 }}
          >
            <Button size={"large"} variant={"contained"} href={"/"}>
              {t("Common.back")}
            </Button>
            <Button
              size={"large"}
              variant={"contained"}
              onClick={() => setCounter(counter + 1)}
            >
              {t("Common.accept")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ImportantNote;
