/* eslint-disable quotes */

const surveyJson = {
  "locale": "de",
  "title": "Pump-o-Mat",
  "logoPosition": "right",
  "completedHtml": "1. {first} <br/> 2. {second} <br/>3. {third} ",
  "pages": [
    {
      "name": "page1",
      "elements": [
        {
          "type": "text",
          "name": "question1",
          "title": {
            "de": "Wie lange hast du schon Diabetes?",
            "default": "How long have you had diabetes?"
          }
        },
        {
          "type": "radiogroup",
          "name": "question2",
          "title": {
            "de": "Was nutzt du zur Zeit um dir Insulin zu spritzen?",
            "default": "What do you currently use to inject yourself with insulin?"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "item2",
              "text": {
                "de": "Pen für schnellwirkendes Insulin",
                "default": "Pen for rapid-acting insulin"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item3",
              "text": {
                "de": "Pen für Basal-Insulin",
                "default": "Pen for basal insulin"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item4",
              "text": "Accu-Chek Insight",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item12",
              "text": "Accu-Chek Combo",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item5",
              "text": "Omnipod",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item6",
              "text": {
                "de": "mylife YpsoPump",
                "default": "Ypsopump"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item7",
              "text": "Dana",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item8",
              "text": {
                "de": "Medtronic 780G",
                "default": "Medtronic"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item9",
              "text": "Medtronic 640",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item10",
              "text": "Medtronic 772",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item11",
              "text": {
                "de": "t:slim X2",
                "default": "t:slim"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            }
          ],
          "showOtherItem": true
        },
        {
          "type": "radiogroup",
          "name": "question4",
          "title": {
            "de": "Was trifft am ehesten auf dich zu?",
            "default": "What do you currently use to inject yourself with insulin?"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "self",
              "text": {
                "de": "Ich möchte, dass mein System meine Therapie optimiert, aber ich immer noch die Zügel in der Hand halte.",
                "default": "Pen for rapid-acting insulin"
              },
              "scoretslimx2": 10,
              "scoremedtronic780": 0,
              "scoreypsopump": 5,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "less",
              "text": {
                "de": "Ich möchte einen Großteil der Arbeit an mein System abgeben und nur wenig in das System eingreifen.",
                "default": "I would like to hand over a large part of the work to my system and only intervene a little in the system"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 5
            },
            {
              "value": "no",
              "text": {
                "de": "Ich möchte so viel Arbeit wie möglich an das System abgeben und nur wenn nötig eingreifen.",
                "default": "I want to hand over as much work as possible to the system and only intervene when necessary."
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 10,
              "scoreomnipod5": 0
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question6",
          "title": {
            "de": "Sollte Ihr AID-System sich an veränderte Tagesabläufe oder Insulinbedarfe rasch anpassen?",
            "default": "Should your AID system adapt quickly to changing daily routines or insulin needs?"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "self",
              "text": {
                "de": "Ja, ich arbeite z.B. im Schichtbetrieb",
                "default": "Yes, I work in shifts, for example"
              },
              "scoretslimx2": 10,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 10,
              "scoreomnipod5": 0
            },
            {
              "value": "less",
              "text": {
                "de": "nein, mein Alltag ist sehr regelmäßig",
                "default": "no, my everyday life is very regular"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 5,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 5
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "cgmFrage",
          "title": {
            "de": "Benutzt du bereits eine kontinuierliches Glukosemess-System (CGM)? Falls ja, welches?",
            "default": "Are you already using a continuous glucose monitoring (CGM) system? If yes, which one?"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "item1",
              "text": {
                "de": "Nein",
                "default": "No"
              },
              "scoretslimx2": 10,
              "scoremedtronic780": 10,
              "scoreypsopump": 10,
              "scoredana": 10,
              "scoreomnipod5": 10
            },
            {
              "value": "item7",
              "text": "Libre 3",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item5",
              "text": "Libre 2 ",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item8",
              "text": "Dexcom G7",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item3",
              "text": {
                "de": "Dexcom G6",
                "default": "Dexcom"
              },
              "scoretslimx2": 10,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 10,
              "scoreomnipod5": 10
            },
            {
              "value": "item2",
              "text": {
                "de": "Minimed (Enlite/Guardian)",
                "default": "Minimed"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 10,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item4",
              "text": "Eversense",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item6",
              "text": "GlucoMenDay",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            }
          ],
          "showOtherItem": true
        },
        {
          "type": "radiogroup",
          "name": "question3",
          "visibleIf": "{cgmFrage} anyof ['item5', 'item4', 'item3', 'item2']",
          "title": {
            "de": "Würdest du dein CGM für eine Pumpe wechseln, wenn es dadurch mehr Funktionen hat?",
            "default": "Would you change your CGM for a pump if it gave it more features?"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "item1",
              "text": {
                "de": "Ja",
                "default": "Yes"
              },
              "scoretslimx2": 10,
              "scoremedtronic780": 10,
              "scoreypsopump": 10,
              "scoredana": 10,
              "scoreomnipod5": 10
            },
            {
              "value": "item2",
              "text": {
                "de": "Nein",
                "default": "No"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item3",
              "text": {
                "de": "Ich bräuchte mehr Informationen",
                "default": "I need more information"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "insulinEinheiten",
          "title": {
            "de": "Wie viel Insulin brauchst du täglich insgesamt, um deine Basalrate und Mahlzeiten abzudecken?",
            "default": "How much total insulin do you need daily to cover your basal rate and meals?"
          },
          "description": {
            "de": "Das Insulin in der Insulinampulle der Pumpe sollte mindestens zwei Tage reichen, aber möglichst nicht länger als eine Woche (Destabilisierung bei Hitze im Sommer).",
            "default": "The insulin in the insulin ampoule of the pump should last at least two days, but preferably not longer than a week (destabilization in the heat in summer)."
          },
          "isRequired": true,
          "choices": [
            {
              "value": "lowerThan70",
              "text": "<60 IE",
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 15,
              "scoredana": 5,
              "scoreomnipod5": 15
            },
            {
              "value": "between70and80",
              "text": "60-75 IE",
              "scoretslimx2": 10,
              "scoremedtronic780": 10,
              "scoreypsopump": 5,
              "scoredana": 10,
              "scoreomnipod5": 8
            },
            {
              "value": "higherThan80",
              "text": ">75 IE",
              "scoretslimx2": 15,
              "scoremedtronic780": 15,
              "scoreypsopump": 5,
              "scoredana": 15,
              "scoreomnipod5": 0
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "pflaster",
          "title": {
            "de": "Hast du eine Unverträglichkeit auf Pflaster?",
            "default": "Do you have an intolerance to patches?"
          },
          "description": {
            "de": "Wenn du eine Pflasterunverträglichkeit hast, sind Schlauchpumpen zu empfehlen, da mehrere Katheter-Arten zur Verfügung stehen und man oft einen verträglichen Katheter finden kann. Bei schlauchlosen Pumpen gibt es keine Alternativen.",
            "default": "If you have patch intolerance, peristaltic pumps are recommended because several catheter types are available and you can often find a compatible catheter. There are no alternatives for tubeless pumps."
          },
          "isRequired": true,
          "choices": [
            {
              "value": "ja",
              "text": {
                "de": "Ja",
                "default": "Yes"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 0
            },
            {
              "value": "nein",
              "text": {
                "de": "Nein",
                "default": "No"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 5
            },
            {
              "value": "egal",
              "text": {
                "de": "Weiß ich nicht",
                "default": "I do not know"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "pumpenSichtbarkeit",
          "title": {
            "de": "Wie möchtest du die Pumpe tragen?",
            "default": "How would you like to wear the pump on a daily basis?"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "sichtbar",
              "text": {
                "de": "In der Hosentasche, am Gürtel oder Rock-/Hosenbund um das display zu sehen und darüber einen bolus abzugeben",
                "default": "I would like to hold the pump in my hand and see the display each time I deliver a bolus (wear in my pocket, on my belt or skirt/short waistband)."
              },
              "scoretslimx2": 10,
              "scoremedtronic780": 10,
              "scoreypsopump": 10,
              "scoredana": 10,
              "scoreomnipod5": 0
            },
            {
              "value": "fühlbar",
              "text": {
                "de": "Unter leichter Kleidung an am Bauch-, Arm oder Bein, ich möchte die Pumpe nicht in die Hand nehmen",
                "default": "I do not need to pick up the pump, but would like to feel it through my clothing. Meal insulin can be delivered by feeling the control buttons (wearing under thin clothing on a waist or arm belt)."
              },
              "scoretslimx2": 10,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 0,
              "scoreomnipod5": 10
            },
            {
              "value": "versteckt",
              "text": {
                "de": "In der Unterwäsche, am BH, an Oberschenkel- oder Unterschenkelgurt, unter dickerer Kleidung).",
                "default": "I do not want to have to hold the pump in public. I want to wear the pump on parts of my body that I have difficulty feeling (wearing in my underwear, on a bra, on a thigh or lower leg belt, under thicker clothing)."
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 10,
              "scoreomnipod5": 15
            }
          ]
        }
      ]
    },
    {
      "name": "page2",
      "elements": [
        {
          "type": "radiogroup",
          "name": "sportFrage",
          "title": {
            "de": "Führst du Tätigkeiten aus, bei denen die Pumpe zeitweilig nicht am Körper getragen werden kann?",
            "default": "Do you perform activities where the pump cannot be worn on the body at times?"
          },
          "description": {
            "de": "(Sport, Saunabesuche etc.)",
            "default": "(Sports with body contact, sauna visits, etc.)"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "item1",
              "text": {
                "de": "Ja",
                "default": "Yes"
              },
              "scoretslimx2": 10,
              "scoremedtronic780": 10,
              "scoreypsopump": 10,
              "scoredana": 10,
              "scoreomnipod5": 0
            },
            {
              "value": "item2",
              "text": {
                "de": "Ja, aber das ist mir nicht wichtig",
                "default": "Yes, but this is not important to me"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 5
            },
            {
              "value": "item3",
              "text": {
                "de": "Nein",
                "default": "No"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 5
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "smartphoneSteuerung",
          "title": {
            "de": "Wie würdest Du Dein AID-System am liebsten steuern?",
            "default": "Do you use a smartphone and would you use this as part of managing your diabetes?"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "item1",
              "text": {
                "de": "über mein Smartphone",
                "default": "Yes"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 10,
              "scoreomnipod5": 10
            },
            {
              "value": "item2",
              "text": {
                "de": "über die Insulinpumpe",
                "default": "No"
              },
              "scoretslimx2": 15,
              "scoremedtronic780": 15,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 0
            },
            {
              "value": "item3",
              "text": {
                "de": "über eine extra Bediengerät",
                "default": "I do not know"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 10
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question5",
          "visibleIf": "{smartphoneSteuerung} = 'item1'",
          "title": {
            "de": "Nutzt du Android oder iOS?",
            "default": "Do you use Android or iOS?"
          },
          "choices": [
            {
              "value": "item1",
              "text": "Android",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 10,
              "scoreomnipod5": 10
            },
            {
              "value": "item2",
              "text": "iOS",
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            }
          ],
          "showOtherItem": true
        }
      ]
    },
    {
      "name": "page3",
      "elements": [
        {
          "type": "radiogroup",
          "name": "pumpenGroesse",
          "title": {
            "de": "Spielt die Größe der Insulinpumpe für dich eine Rolle?",
            "default": "Does the size of the insulin pump matter to you?"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "item1",
              "text": {
                "de": "Nein",
                "default": "No"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 5
            },
            {
              "value": "item2",
              "text": {
                "de": "Wenn möglich, klein",
                "default": "If possible, small"
              },
              "scoretslimx2": 9,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 7,
              "scoreomnipod5": 10
            },
            {
              "value": "item3",
              "text": {
                "de": "Für mich muss die Pumpe besonders klein sein",
                "default": "For me the pump has to be especially small"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 0,
              "scoreomnipod5": 10
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "question7",
          "title": {
            "de": "Sollte Dein AID-System für die Schwangerschaft zugelassen sein?",
            "default": "Does the size of the insulin pump matter to you?"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "item1",
              "text": {
                "de": "ja",
                "default": "No"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 100,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item2",
              "text": {
                "de": "nein",
                "default": "If possible, small"
              },
              "scoretslimx2": 9,
              "scoremedtronic780": 0,
              "scoreypsopump": 10,
              "scoredana": 7,
              "scoreomnipod5": 10
            }
          ]
        }
      ]
    },
    {
      "name": "page5",
      "elements": [
        {
          "type": "radiogroup",
          "name": "befuellteAmpulle",
          "title": {
            "de": "Möchtest du Insulin in einer vorgefüllten Ampulle verwenden?",
            "default": "Do you want to use insulin in a prefilled vial?"
          },
          "description": {
            "de": "Nicht alle Insuline sind in vorgefüllten Ampullen verfügbar. Daher kann es sinnvoll sein, seine Ampullen selbst zu befüllen",
            "default": "Not all insulins are available in pre-filled ampoules. Therefore it may be useful to fill your ampoules yourself"
          },
          "isRequired": true,
          "choices": [
            {
              "value": "item1",
              "text": {
                "de": "Nein",
                "default": "No"
              },
              "scoretslimx2": 3,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item2",
              "text": {
                "de": "Ja, aber es ist mir nicht so wichtig",
                "default": "Yes, but it is not that important to me"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 3,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item3",
              "text": {
                "de": "Ja, unbedingt",
                "default": "Yes, absolutely"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 15,
              "scoredana": 0,
              "scoreomnipod5": 0
            }
          ]
        },
        {
          "type": "radiogroup",
          "name": "teflonProbleme",
          "visibleIf": "{question2} anyof ['item4', 'item5', 'item6', 'item7', 'item8', 'item9']",
          "title": {
            "de": "Hattest du bei deiner bisherigen Insulinpumpentherapie Probleme mit Teflon-Kathetern?",
            "default": "Have you had problems with Teflon catheters during previous insulin pump therapy?"
          },
          "isRequired": true,
          "commentPlaceholder": {
            "de": "Diese Frage bedingt an Pen / Pumpe",
            "default": "This question is conditional to pen / pump"
          },
          "choices": [
            {
              "value": "item1",
              "text": {
                "de": "Ja",
                "default": "Yes"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 0
            },
            {
              "value": "item2",
              "text": {
                "de": "Nein",
                "default": "No"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item3",
              "text": {
                "de": "Weiß ich nicht",
                "default": "I do not know"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 5
            }
          ]
        }
      ]
    },
    {
      "name": "page4",
      "elements": [
        {
          "type": "radiogroup",
          "name": "question8",
          "visibleIf": "{question2} anyof ['item4', 'item5', 'item6', 'item7', 'item8', 'item9']",
          "title": {
            "de": "Wie alt bist Du?",
            "default": "Have you had problems with Teflon catheters during previous insulin pump therapy?"
          },
          "isRequired": true,
          "commentPlaceholder": {
            "de": "Diese Frage bedingt an Pen / Pumpe",
            "default": "This question is conditional to pen / pump"
          },
          "choices": [
            {
              "value": "item1",
              "text": {
                "de": "< 1 Jahr",
                "default": "Yes"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 0
            },
            {
              "value": "item2",
              "text": {
                "de": "2 - 6 Jahre",
                "default": "No"
              },
              "scoretslimx2": 0,
              "scoremedtronic780": 0,
              "scoreypsopump": 0,
              "scoredana": 0,
              "scoreomnipod5": 0
            },
            {
              "value": "item3",
              "text": {
                "de": "7 - 17 Jahre",
                "default": "I do not know"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 5
            },
            {
              "value": "item4",
              "text": {
                "de": "< 18 Jahre"
              },
              "scoretslimx2": 5,
              "scoremedtronic780": 5,
              "scoreypsopump": 5,
              "scoredana": 5,
              "scoreomnipod5": 5
            }
          ]
        }
      ]
    }
  ],
  "triggers": [
    {
      "type": "setvalue",
      "expression": "{insulinEinheiten} = ['lowerThan70']",
      "setToName": "empfehlungINS",
      "setValue": "Accu-Chek® Insight",
      "isVariable": true
    }
  ],
  "questionsOnPageMode": "questionPerPage"
}
export default surveyJson;
