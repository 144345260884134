import Types from "./Types";

const pumpData = {
  "totalScoreTslimX2": {
    "name": "Tandem - t:slim X2",
    "description": "Tandem t:slim X2 with intelligent algorithm and slim design. Flexible basal rates, delayed insulin delivery and automatic mode “Control IQ” in combination with Dexcom G6 sensor provide individual support for people with diabetes.",
    "description_de": "Tandem t:slim X2 mit intelligentem Algorithmus und schlankem Design. Flexibel einstellbare Basalraten, verzögerte Insulinabgabe und automatischer Modus “Control IQ” in Verbindung mit Dexcom G6-Sensor bieten eine individuelle Unterstützung für Menschen mit Diabetes.",
    "link": "https://www.heymeala.com/tandem-tslim-x2/",
    "video": "https://www.youtube-nocookie.com/embed/kj_WEuYisDA?si=maQAuUZ_IDNatUmG"
  },
  "totalScoreMedtronic780": {
    "name": "Medtronic 780G",
    "description": "The Medtronic 780G is an excellent choice for people with diabetes who are looking for a system that allows for largely automated therapy and requires only minimal manual intervention.",
    "description_de": "Die Medtronic 780G ist eine hervorragende Wahl für Menschen mit Diabetes, die ein System suchen, das eine weitgehend automatisierte Therapie ermöglicht und nur wenig manuelle Eingriffe erfordert.",
    "link": "https://www.heymeala.com/medtronic-minimed-780/",
    "video": "https://www.youtube-nocookie.com/embed/VJmuhYPSWww?si=UCbVE8JC0PJUMAYN",
    "reviewVideo":"https://www.youtube-nocookie.com/embed/pVlzd8MmdT4?si=BOWoODc6zzmgxmng"

  },
  "totalScoreYpsopump": {
    "name": "Ypsomed",
    "description": "The mylife YpsoPump - a compact and easy-to-use insulin pump with the innovative CamAPS FX system compatible. With the clear CamAPS FX app on your own smartphone, the system is easy to use and automatically determines the amount of insulin needed.",
    "description_de": "Die mylife YpsoPump - eine kompakte und einfach zu bedienende Insulinpumpe mit dem innovativen CamAPS FX System kompatibel. Mit der übersichtlichen CamAPS FX-App auf dem eigenen Handy ist das System einfach zu nutzen und ermittelt automatisch die benötigte Insulinmenge.",
    "link": "https://www.heymeala.com/mylife-ypsopump/",
    "video": "https://www.youtube-nocookie.com/embed/vAoFwKJHVWU?si=m8hW7jE93nqznhhn",
  },
  "totalScoreDana": {
    "name": "Dana RS bzw. Dana I",
    "description": "Simple to use insulin pump system with mobile phone control",
    "description_de": "Einfach zu bedienendes Insulinpumpensystem mit Handy-Steuerung",
    "link": "https://www.heymeala.com/dana-rs-bzw-dana-i/",
    "video": "https://www.youtube-nocookie.com/embed/aHd4HnL0OHA?si=sgnl5sJK_3JDoBPX"
  },
  "totalScoreOmnipod5": {
    "name": "Insulet - Omnipod 5",
    "description": "An innovative AID system without infusion set and tube, which has already achieved great success in the USA. The bolus calculator takes into account the CGM trend and the system is easy to use, but note that it needs a meal bolus for coverage and people who rarely give bolus may have less success.",
    "description_de": "Ein innovatives AID-System ohne Infusionsset und Schlauch, das in den USA bereits große Erfolge verzeichnet. Der Bolusrechner berücksichtigt den CGM-Trend und das System ist einfach zu bedienen, aber beachten Sie, dass es für die Abdeckung von Mahlzeiten Bolus benötigt und Menschen, die selten Bolus abgeben, möglicherweise weniger Erfolg haben.",
    "link": "https://www.heymeala.com/omnipod-5",
    "video":"https://www.youtube.com/embed/74XfhPiOXgU?si=06oBHiAQdSP-2JWh"
  },
  "default": {
    "name": "-",
    "description": "No description available.",
    "description_de": "Keine Beschreibung verfügbar.",
    "link": "",
    "video": null
  }
};


export default pumpData;
