const Logo = () => {
  return (
    <svg id="a" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.01 30.52">
      <path d="m7.58,13.33h-3.98v8.91H0V.28h7.58c5.21,0,7.77,2.97,7.77,6.54,0,3.13-2.05,6.51-7.77,6.51Zm0-2.94c2.81,0,4.08-1.36,4.08-3.57s-1.26-3.6-4.08-3.6h-3.98v7.17h3.98Z" fill="#1d1d1b" strokeWidth="0"/>
      <path d="m34.56,22.24h-3.6v-2.09c-1.14,1.48-3.1,2.34-5.15,2.34-4.08,0-7.17-2.56-7.17-7.42V4.83h3.57v9.7c0,3.19,1.74,4.83,4.36,4.83s4.39-1.64,4.39-4.83V4.83h3.6v17.41Z" fill="#1d1d1b" strokeWidth="0"/>
      <path d="m63.98,12.51c0-3.19-1.74-4.83-4.36-4.83s-4.39,1.64-4.39,4.83v9.73h-3.57v-9.73c0-3.19-1.74-4.83-4.36-4.83s-4.39,1.64-4.39,4.83v9.73h-3.6V4.83h3.6v1.99c1.17-1.42,3.06-2.27,5.15-2.27,2.75,0,5.06,1.17,6.26,3.44,1.07-2.12,3.54-3.44,6.07-3.44,4.08,0,7.17,2.56,7.17,7.42v10.27h-3.57v-9.73Z" fill="#1d1d1b" strokeWidth="0"/>
      <path d="m81.8,4.55c4.58,0,8.18,3.54,8.18,8.91s-3.6,9.07-8.18,9.07c-2.88,0-4.93-1.45-6.07-2.81v10.81h-3.6V4.83h3.6v2.56c1.11-1.45,3.22-2.84,6.07-2.84Zm-.79,3.13c-2.69,0-5.28,2.12-5.28,5.85s2.59,5.88,5.28,5.88,5.31-2.18,5.31-5.94-2.59-5.78-5.31-5.78Z" fill="#1d1d1b" strokeWidth="0"/>
      <path d="m93.93,9.29h12.99v3.03h-12.99v-3.03Z" fill="#1d1d1b" strokeWidth="0"/>
      <path d="m122.05,22.46c-6.19,0-11.22-4.64-11.22-11.25S115.86,0,122.05,0s11.22,4.64,11.22,11.22-4.96,11.25-11.22,11.25Zm0-3.13c4.36,0,7.52-3.16,7.52-8.12s-3.16-8.06-7.52-8.06-7.52,3.1-7.52,8.06,3.16,8.12,7.52,8.12Z" fill="#1d1d1b" strokeWidth="0"/>
      <path d="m137.18,9.29h12.99v3.03h-12.99v-3.03Z" fill="#1d1d1b" strokeWidth="0"/>
      <path d="m155.29.28h3.89l7.71,17.22L174.56.28h3.85v21.96h-3.6V7.2l-6.7,15.04h-2.5l-6.73-15.04v15.04h-3.6V.28Z" fill="#1d1d1b" strokeWidth="0"/>
      <path d="m190.17,4.55c2.94,0,4.96,1.39,6.03,2.81v-2.53h3.63v17.41h-3.63v-2.59c-1.11,1.49-3.19,2.88-6.1,2.88-4.52,0-8.15-3.7-8.15-9.07s3.63-8.91,8.21-8.91Zm.76,3.13c-2.69,0-5.28,2.02-5.28,5.78s2.59,5.94,5.28,5.94,5.28-2.12,5.28-5.88-2.56-5.85-5.28-5.85Z" fill="#1d1d1b" strokeWidth="0"/>
      <path d="m205.14,7.77h-2.05v-2.94h2.05V.51h3.63v4.33h4.23v2.94h-4.23v9.64c0,1.3.51,1.83,2.02,1.83h2.21v3h-2.84c-3.1,0-5.02-1.3-5.02-4.83V7.77Z" fill="#1d1d1b" strokeWidth="0"/>
    </svg>
  );
};
export default Logo;
